import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BenefitsService } from '@services/benefits.service';
import { BannerNotificationType, NotificationsService } from '@services/notifications.service';
import { UserService } from '@services/user.service';
import { mask } from '@utils/policy-id-obfuscator';
import { LOCAL_STORAGE_TOKEN } from 'src/app/utils/tokens';

import { ApiService } from './api.service';
import { PaymentMicroService } from './payment-micro.service';
import { BeneficiaryTypeUnion, Benefit, Contact, ContactResponse, Policy, SecondaryAddressee } from './types';
import { BeneficiaryTypeMap } from './types/Beneficiary';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// maybe disappears
export enum BeneficiaryType {
  Insured = 'insured',
  Owner = 'owner',
  OwnerIsInsured = 'ownerIsInsured',
  SecondaryAddressee = 'secondaryAddressee'
}

// maybe disappears
export const BeneficiaryTypeNames = {
  [BeneficiaryType.Insured]: 'Insured',
  [BeneficiaryType.Owner]: 'Owner',
  [BeneficiaryType.OwnerIsInsured]: 'Owner & Insured',
  [BeneficiaryType.SecondaryAddressee]: 'Secondary Addressee',
};

export enum PolicyStatus {
  applied = 'applied',
  active = 'active',
  approved = 'approved',
  pending = 'pending',
  declined = 'declined',
  lapse = 'lapse',
  cancelled = 'cancelled',
  surrender = 'surrender',
  freeLook = 'free_look',
  grace = 'grace',
}

export enum BankVerificationStatus {
  /**
   * The Item is pending automatic verification
   */
  pendingAutomatic = 'pending_automatic_verification',
  /**
   * The Item is pending manual micro-deposit verification.
   * Items remain in this state until the user successfully
   * verifies the two amounts.
   */
  pendingManual = 'pending_manual_verification',
  /**
   * The Item has successfully been automatically verified
   */
  automaticallyVerified = 'automatically_verified',
  /**
   * The Item has successfully been manually verified
   */
  manuallyVerified = 'manually_verified',
  /**
   * Plaid was unable to automatically verify the deposit within 7
   * calendar days and will no longer attempt to validate the Item.
   * Users may retry by submitting their information again through Link.
   */
  verificationExpired = 'verification_expired',
  /**
   * The Item failed manual micro-deposit verification because the user
   * exhausted all 3 verification attempts. Users may retry by submitting
   * their information again through Link.
   */
  verificationFailed = 'verification_failed',
  /**
   * micro-deposit-based verification is not being used for the Item.
   */
  null = 'null'
}

export interface GracePeriods {
  endTimestamp: string;
  startTimestamp: string;
  end_date_formatted?: string;
}

// TODO(davmau): We should remove this in favor of CustomerResponse, but need to check if it's used anywhere
export interface Customer {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  policies: {
    number: string,
    status?: PolicyStatus,
    gracePeriods?: GracePeriods[]
  }[];
}

// TODO(edulop): Figure out if you need to extend Customer interface,
// new type, etc, to better fit the use of this interface. (#564059)
export interface CustomerResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  policies: CustomerResponsePolicy[];
}

export interface CustomerResponsePolicy {
  number: string;
  status: PolicyStatus;
  grace_periods?: GracePeriods[],
  bank_info?: {
    bank_token: string;
    bank_name: string;
    bank_account_last_four: string;
  };
  code: string;
  paymentInterval?: number;
  paymentAmount?: number;
  error: {
      hasError: boolean,
      errorMessage: string
    },
  stripeSubscriptionId?: string
}

export interface RateDecision {
  appliedVersion: string // date like "2023-02-14",
  reasonNote: string
  appCreatedDate: string // empty in testing
  decisionDate: string // timestamp like "2023-02-28T04:06:46.495Z"
}

/**
 * /policy/{policyId} response
 */
export interface PolicyResponse {
  owner: string
  owner_username: string
  term_effective_date: string
  term_end_date: string
  face_amount: number
  status: string // PolicyStatus?
  is_contract_visible: boolean
  issue_age: number
  uw_approval_date: string // may be date, but has been empty in testing
  uw_path: string // empty in testing
  uw_channel: string // 'ONLINE' in testing, but could be 'SQ', 'BGA', etc
  grace_periods: GracePeriods[]
  rate_decisions: RateDecision[]
}

interface BeneficiaryUpdateResponse {
  message: string;
}

export interface PolicyDetailsResponse {
  codeStatus: string // "valid"
  appDetails: {
    agent: {
      email: string
      phone: string // "0010010011"
    },
    benefits: string[] // Object.keys(BenefitsService.benefits)
    channel: string // "ONLINE"
    coverageAmount: number, // 350000
    expiration: number // 1678766806.79 (timestamp)
    monthlyCost: number // 3578 (cents)
    payment: {
      1: number // 3578 (cents) monthly
      3: number // 10735 (cents) quarterly
      6: number // 21469 (cents) semi-annually
      12: number // 42097 (cents) annually
    }
    policyNumber: string // "ST99520840"
    role: string // "Insured"
    termLength: number // 10
    owner: {
      firstName: string
      lastName: string
      email: string
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends ApiService {
  private customer: CustomerResponse;

  constructor(
    protected http: HttpClient,
    private router: Router,
    // TODO(davmau): looks like this can be removed from here
    @Inject(LOCAL_STORAGE_TOKEN) private storage: Storage,
    private userService: UserService,
    private benefitsService: BenefitsService,
    private notificationsService: NotificationsService,
    private paymentMicroService: PaymentMicroService
  ) {
    super(http);
  }

  /**
   * TODO(davmau):
   * - This method should not cause navigations as it's purpose is to get the policy
   *   - The navigation should probably be done in the component that calls this method
   *   - If multiple components need to do the same navigation, then it should be done in a different common location, not this service
   *   - Calling this multiple times in a row may cause multiple navigations
   * - Should this method actually check if the policy is active? It seems like it should be the responsibility of the component that calls this method to check if the policy is active
   * - Should this method set the banner notifications? It seems like it should be the responsibility of the component that calls this method to set the banner notifications
   * - This method should probably just get the policy, cache it and return it
   */
  getPolicy(policyId?: string, skipActiveCheck = false): any {
    return this.get(`/policy/${policyId}`).pipe(
      map((response: Policy) => {
        if (response.status === PolicyStatus.lapse) {
          this.router.navigateByUrl('/dashboard');
        } else if (
          !skipActiveCheck &&
          response.status !== PolicyStatus.active &&
          response.status !== PolicyStatus.grace
        ) {
          this.router.navigateByUrl(`/onboarding/${mask(
              policyId
            )}`);
        }
        return response;
      }),
      switchMap((policyResults: Policy) => {
        if (policyResults.status === PolicyStatus.grace) {
          return this.paymentMicroService.getTotalAmountDue(policyId)
            .pipe(
              map((totalAmountDue) => {
                policyResults.total_amount_due = totalAmountDue;
                
                /**
                 * Payment is pending, policy still in grace but 
                 * no past due invoices in Stripe
                 *  */ 
                if (policyResults.total_amount_due === 0) {
                  this.notificationsService.setBanner(null);
                  return policyResults
                }
                
                const latestIndex = policyResults.grace_periods.length - 1;
                const latestGracePeriod: GracePeriods = policyResults.grace_periods[latestIndex];
                const dateAsNumber = parseInt(latestGracePeriod.endTimestamp, 10);
                const endGraceDate = dayjs(dateAsNumber).tz('America/Los_Angeles').format('MMM D h:mma z, YYYY');
                policyResults.grace_periods[latestIndex].end_date_formatted = endGraceDate;
              
                this.notificationsService.setBanner({
                  type: BannerNotificationType.ERROR,
                  label: 'Action required:',
                  description: `You have an outstanding balance of $${policyResults.total_amount_due}.`,
                  closable: false,
                  linkText: 'Update your payment method',
                  internalLink: `/dashboard/${mask(
                    policyId
                  )}/payments/change-payment-method`,
                  textAfterLink: `before ${endGraceDate} or your policy will lapse`,
                });
                return policyResults
              })
            )
        } else {
          this.notificationsService.setBanner(null);
          return of(policyResults);
        }
      })
  )}

  getBenefits(policyId: string): Observable<Benefit[]> {
    return this.get(`/policy/${policyId}/benefits`).pipe(
      map((response: { benefits: Benefit[] }) => {
        if (response.benefits) {
          return response.benefits.map((benefit) => {
            return this.benefitsService.getBenefitDetails(benefit.name);
          });
        }
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of([]);
        } else {
          return throwError(error);
        }
      })
    );
  }

  getBeneficiary(policyId: string, beneficiaryId: string) {
    return this.get(
      `/policy/${policyId}/beneficiaries/${beneficiaryId}`
    ) ;
  }

  getBeneficiaries(policyId: string): Observable<BeneficiaryTypeUnion[]> {
    return this.get(`/policy/${policyId}/beneficiaries`).pipe(
      map((response: { beneficiaries: any[] }) => {
        if (response.beneficiaries) {
          return response.beneficiaries.map((beneficiary) => {
            const BeneficiaryClass =
              BeneficiaryTypeMap[beneficiary.beneficiaryType];
            const newBeneficiary = new BeneficiaryClass(beneficiary);
            newBeneficiary.percentage = parseInt(beneficiary.percentage, 10);
            return newBeneficiary as BeneficiaryTypeUnion;
          });
        } else {
          return [];
        }
      })
    );
  }

  getBeneficiariesOfType(policyId: string, isPrimary: boolean) {
    return this.getBeneficiaries(policyId).pipe(
      map((beneficiaries) =>
        beneficiaries.filter((b) => b.primary === isPrimary)
      )
    );
  }

  updateBeneficiary(policyId: string, beneficiary: BeneficiaryTypeUnion) {
    return this.put<BeneficiaryUpdateResponse>(
      `/policy/${policyId}/beneficiaries/${beneficiary.beneficiaryId}`,
      beneficiary
    ) ;
  }

  updateAllocations(policyId: string, beneficiaries: BeneficiaryTypeUnion[]) {
    return this.put<BeneficiaryUpdateResponse>(
      `/policy/${policyId}/beneficiaries`,
      beneficiaries
    ) ;
  }

  createBeneficiary(policyId: string, data: any) {
    // TODO: Number fields are being set as string from form input
    // TODO: NEED TO ASK CHRIS!
    return this.post(`/policy/${policyId}/beneficiaries`, data);
  }

  deletePolicyRider(benefitId: string) {
    // no API repo or API gateway service. need to create one
    return this.delete(`/policy/benefits/${benefitId}`);
  }

  updatePolicyOwnerAddress(policyId: string, address) {
    return this.put(`/policy/${policyId}/address`, address);
  }

  updateBeneficiaryAddress(beneficiaryId, address) {
    // This endpoint doesn't exist
    return this.post(`/policy/beneficiary/${beneficiaryId}/address`, address);
  }

  /**
   * This method is only used by the dashboard-contract-download component, which is not used anywhere
   * TODO(davmau): if removing (or have removed) the dashboard-contract-download component, remove this method
   */
  getContract(policyId: string): Observable<HttpResponse<string>> {
    return this.get<HttpResponse<string>>(`/policy/${policyId}/contract`, { observe: 'response' });
  }

  // TODO(davmau): seems like this method should be in a customer.service.ts (does not exist yet)
  // We cache the customer since it is used and required for much of the application
  getCustomer(force = false): Observable<CustomerResponse> {
    if (this.customer && !force) {
      return of(this.customer);
    } else {
      let url = `/customer`;
      
      return this.get(url).pipe(
        map((response: any) => {
          const customer = {
            firstName: response.first_name,
            lastName: response.last_name,
            suffix: response.suffix_name,
            policies: response.policies
          };
          this.customer = customer;

          this.userService.setFirstName((response ).first_name);
          this.userService.setLastName((response ).last_name);
          this.userService.setSuffixName((response ).suffix_name);

          return customer;
        })
      );
    }
  }

  getContacts(policyId: string): Observable<Contact[]> {
    return this.get<ContactResponse>(`/policy/${policyId}/contacts`).pipe(
      map((response) => {
        response.contacts.forEach((item) => {
          const names = [
            item.firstName,
            item.middleName,
            item.lastName,
            item.suffix,
          ];
          const fullName = names.filter((prop) => prop != null);
          item.fullName = fullName.join(' ');
        });

        return response.contacts;
      })
    );
  }

  updateContact(policyId: string, contact: Contact) {
    return this.put(`/policy/${policyId}/contacts`, contact);
  }

  getSecondaryAddressee(policyId: string) {
    return this.get<SecondaryAddressee>(
      `/policy/${policyId}/address/secondary`
    );
  }

  updateSecondaryAddressee(policyId: string, addresseeId: string, data: any) {
    return this.put<SecondaryAddressee>(
      `/policy/${policyId}/address/secondary/${addresseeId}`,
      data
    );
  }

  deleteSecondaryAddressee(policyId: string, addresseeId: string) {
    return this.delete(`/policy/${policyId}/address/secondary/${addresseeId}`);
  }

  createSecondaryAddressee(policyId: string, data: any) {
    return this.post(`/policy/${policyId}/address/secondary`, data);
  }

  getPolicyDetails(policyId: string): Observable<PolicyDetailsResponse> {
    return this.get<PolicyDetailsResponse>(`/policy/${policyId}/details`);
  }
}
